<template>
  <div v-click-outside="hide"
       class="sk-date-picker">
    <input v-model="selectedDate"
           class="sk-date-picker__value month-picker-input"
           type="text"
           readonly
           @click="showMonthPicker()">
    <month-picker v-show="monthPickerVisible"
                  :default-year="defaultYear"
                  :default-month="defaultMonth"
                  :default-month-range="defaultMonthRange"
                  :lang="lang"
                  :months="months"
                  :no-default="noDefault"
                  :show-year="showYear"
                  :clearable="clearable"
                  :variant="variant"
                  :editable-year="editableYear"
                  :max-date="maxDate"
                  :min-date="minDate"
                  :year-only="yearOnly"
                  :range="range"
                  @input="populateInput"
                  @change="$emit('change', $event)" />
  </div>
</template>

<script>
  import MonthPicker from './MonthPicker';
  import MonthPickerPrototype from './MonthPickerPrototype';

  export default {
    directives: {
      clickOutside: {
        bind: (el, binding, vnode) => {
          el.event = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
              vnode.context[binding.expression](event);
            }
          };
          document.body.addEventListener('click', el.event);
        },
        unbind: (el) => {
          document.body.removeEventListener('click', el.event);
        }
      }
    },
    components: {
      'month-picker': MonthPicker
    },
    extends: MonthPickerPrototype,
    data() {
      return {
        monthPickerVisible: false
      };
    },
    computed: {
      selectedDate() {
        if (this.inputPreFilled && this.defaultMonth !== null && this.defaultYear !== null && !this.range) {
          return `${this.monthsByLang[this.defaultMonth - 1]}, ${this.defaultYear}`;
        }
        return null;
      }
    },
    methods: {
      populateInput(date) {
        this.monthPickerVisible = false;
        this.$emit('input', date);
      },
      showMonthPicker() {
        this.monthPickerVisible = !this.monthPickerVisible;
      },
      hide() {
        this.monthPickerVisible = false;
      }
    }
  };
</script>

<style scoped>
  .month-picker-input-container {
    position: relative;
    width: 180px;
    min-width: 140px;
  }

  .month-picker-input {
    border: none;
  }

  .month-picker__container {
    position: absolute;
    z-index: 9999;
    width: 100%;
  }
</style>
