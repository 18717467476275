<template>
  <article class="sk-date-picker__wrapper range-picker">
    <div class="range-picker__wrapper">
      <div class="range-picker__date-picker"
           :class="{'range-picker__date-picker--fixed-height': !hasDescription}">
        <p v-if="descriptionFrom"
           class="sk-date-picker__description">{{ descriptionFrom }}</p>
        <monthly-picker :default-month="from.defaultMonth"
                        :default-year="from.defaultYear"
                        :input-pre-filled="true"
                        :lang="currentLang"
                        class="sk-date-picker"
                        @change="setDateFrom" />
      </div>
      <div class="range-picker__date-picker">
        <p v-if="descriptionTo"
           class="sk-date-picker__description">{{ descriptionTo }}</p>
        <monthly-picker :default-month="to.defaultMonth"
                        :default-year="to.defaultYear"
                        :input-pre-filled="true"
                        :min-date="minToDate"
                        :lang="currentLang"
                        class="sk-date-picker"
                        @change="setDateTo" />
      </div>
    </div>
  </article>
</template>

<script>
  import MonthlyPicker from '@/components/shared_components/monthly_picker/MonthPickerInput';
  import MonthPickerPrototype from '@/components/shared_components/monthly_picker/MonthPickerPrototype';

  export default {
    components: {
      'monthly-picker': MonthlyPicker
    },
    extends: MonthPickerPrototype,
    props: {
      descriptionFrom: {
        type: String,
        default: ''
      },
      descriptionTo: {
        type: String,
        default: ''
      },
      from: {
        type: Object,
        default: () => {
          return {
            defaultMonth: '1',
            defaultYear: this.$moment().year()
          };
        },
        required: false,
        validator: (value) => {
          const isObject = typeof value === 'object' && value !== null;
          return isObject && value.defaultMonth && value.defaultYear;
        }
      },
      to: {
        type: Object,
        default: () => {
          return {
            defaultMonth: '1',
            defaultYear: this.$moment().year()
          };
        },
        required: false,
        validator: (value) => {
          const isObject = typeof value === 'object' && value !== null;
          return isObject && value.defaultMonth && value.defaultYear;
        }
      }
    },
    computed: {
      minToDate() { return new Date(this.from.defaultYear, this.from.defaultMonth, 1); },
      currentLang() { return this.$route.params.lang; },
      hasDescription() { return this.descriptionFrom || this.descriptionTo; }
    },
    methods: {
      setDateFrom(date = {}) {
        this.$emit('startdatechanged', date.from);
      },
      setDateTo(date = {}) {
        this.$emit('enddatechanged', date.to);
      }
    }
  };
</script>

<style>
  .range-picker .month-picker {
    width: calc(200% + 10px);
  }

  .range-picker .range-picker__date-picker:last-child .month-picker {
    left: calc(-100% - 10px);
  }
</style>

<style scoped>
  .range-picker__wrapper {
    display: flex;
    justify-content: space-between;
  }

  .range-picker__date-picker {
    position: relative;
    width: calc(50% - 5px);
  }

  .range-picker__date-picker:first-child::after {
    content: '-';
    position: absolute;
    top: 50%;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
  }

  .range-picker__date-picker--fixed-height::after {
    height: 2px;
  }
</style>
